import create from 'zustand';
import engineSlice from './engineSlice';
import translationSlice from './translationSlice';
import userSlice from './userSlice';
import persist from '../utils/persist';
import shipSlice from './shipSlice';
import fileSlice from './fileSlice';
import labelSlice from './labelSlice';
import messageSlice from './messageSlice';
import requestSlice from './requestSlice';
import reportSlice from './reportSlice';
import wizardSlice from './wizardSlice';
import measurementSlice from './measurementSlice';
import fuelSlice from './fuelSlice';
import organizationSlice from './organizationSlice';

const useStore = create(
  persist(
    {
      key: 'emissie',
      allowlist: ['authenticated', 'isAdmin', 'user']
    },
    (set, get) => ({
      ...engineSlice(set, get),
      ...fuelSlice(set, get),
      ...userSlice(set, get),
      ...shipSlice(set, get),
      ...fileSlice(set, get),
      ...labelSlice(set, get),
      ...messageSlice(set, get),
      ...organizationSlice(set, get),
      ...requestSlice(set, get),
      ...reportSlice(set, get),
      ...translationSlice(set),
      ...wizardSlice(set, get),
      ...measurementSlice(set, get)
    })
  )
);

export default useStore;
