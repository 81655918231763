// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_CLIENT = '/client';
const ROOTS_ADMIN = '/admin';
const ROOTS_AUTHORITY = '/authority';
// ----------------------------------------------------------------------

export const PATH_CLIENT = {
  root: ROOTS_CLIENT,
  accept_invite: path(ROOTS_CLIENT, '/acceptInvite'),
  ship: path(ROOTS_CLIENT, '/ship'),
  ship_details: path(ROOTS_CLIENT, '/ship/:id'),
  ship_create: path(ROOTS_CLIENT, '/sip/create'),
  hours: path(ROOTS_CLIENT, '/hours'),
  label: path(ROOTS_CLIENT, '/label'),
  request: path(ROOTS_CLIENT, '/request'),
  organization: path(ROOTS_CLIENT, '/organization'),
  user: path(ROOTS_CLIENT, '/user'),
  wizard: path(ROOTS_CLIENT, '/wizard'),
  help: path(ROOTS_CLIENT, '/help')
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  users: path(ROOTS_ADMIN, '/users'),
  user: path(ROOTS_ADMIN, '/user/:id'),
  user_create: path(ROOTS_ADMIN, '/user/create'),
  requests: path(ROOTS_ADMIN, '/requests'),
  request: path(ROOTS_ADMIN, '/request/:id'),
  engines: path(ROOTS_ADMIN, '/engines'),
  fuels: path(ROOTS_ADMIN, '/fuels')
};

export const PATH_AUTHORITY = {
  root: ROOTS_AUTHORITY,
  labels: path(ROOTS_AUTHORITY, '/labels')
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: '/verify'
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
