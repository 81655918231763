import { Icon } from '@iconify/react';
import dropletOutline from '@iconify/icons-eva/droplet-outline';
import menu2Outline from '@iconify/icons-eva/menu-2-outline';
import awardOutline from '@iconify/icons-eva/award-outline';
import radioButtonOnOutline from '@iconify/icons-eva/radio-button-on-outline';
import questionMarkCircleOutline from '@iconify/icons-eva/question-mark-circle-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import { PATH_CLIENT } from '../../routes/paths';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'new_request',
    path: PATH_CLIENT.wizard,
    icon: getIcon(dropletOutline)
  },
  {
    title: 'my_ships',
    path: '/client/ship',
    icon: getIcon(dropletOutline)
  },
  {
    title: 'engine.quarterly_rapport',
    path: '/client/hours',
    icon: getIcon(radioButtonOnOutline)
  },
  {
    title: 'my_labels',
    path: '/client/label',
    icon: getIcon(awardOutline)
  },
  {
    title: 'my_requests',
    path: '/client/request',
    icon: getIcon(menu2Outline)
  },
  {
    title: 'my_organization',
    path: PATH_CLIENT.organization,
    icon: getIcon(peopleOutline)
  },
  {
    title: 'help',
    path: PATH_CLIENT.help,
    icon: getIcon(questionMarkCircleOutline)
  }
  // {
  //   title: 'my_account',
  //   path: '/account',
  //   icon: getIcon(personOutline)
  // }
];

export default sidebarConfig;
