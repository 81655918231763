import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Stack, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import useStore from '../../../store/useStore';

export default function RegisterForm({ invite }) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const register = useStore((state) => state.register);
  const registering = useStore((state) => state.registering);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('too_short'))
      .max(50, t('too_long'))
      .required(t('first_name_required')),
    lastName: Yup.string()
      .min(2, t('too_short'))
      .max(50, t('too_long'))
      .required(t('last_name_required')),
    email: Yup.string().email(t('email_invalid')).required(t('email_required')),
    password: Yup.string()
      .min(8, t('too_short'))
      .test('regex', t('special_char'), (val) => {
        const regExp = new RegExp('^.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?].*$');
        return regExp.test(val);
      })
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('password_match'))
      .required(t('required'))
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      roles: ['ROLE_USER'],
      invitation: invite
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      register(
        values.email,
        values.password,
        values.firstName,
        values.lastName,
        values.roles,
        true,
        values.invitation
      )
        .then(() => {
          enqueueSnackbar(t('account_created'), {
            variant: 'success'
          });
          navigate('/login', { replace: true });
        })
        .catch((e) => {
          let message = t('generic_error');
          if (e.detail === 'Username taken') message = t('email_taken');

          enqueueSnackbar(message, {
            variant: 'error'
          });
          console.log(e);
        });
    }
  });

  if (!formik.isSubmitting && registering) formik.setSubmitting(true);
  if (formik.isSubmitting && !registering) formik.setSubmitting(false);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const onInputChanged = (e) => {
    setFieldValue('roles', [e.target.value]);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('first_name')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label={t('last_name')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t('email_adress')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            aria-label={t('email_adress')}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={t('password')}
            aria-label={t('password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Show password"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            aria-label={t('confirm_password')}
            label={t('confirm_password')}
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Show password"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
          <TextField
            select
            fullWidth
            SelectProps={{ native: true }}
            label={t('user.type.info')}
            aria-label={t('user.type.info')}
            onChange={(event) => onInputChanged(event)}
          >
            <option key={1} value="ROLE_USER">
              {t('user.type.shipmaster')}
            </option>
            <option key={2} value="ROLE_AUTHORITY">
              {t('user.type.authority')}
            </option>
          </TextField>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            aria-label="register"
          >
            {t('register')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

RegisterForm.propTypes = {
  invite: PropTypes.string
};

RegisterForm.defaultProps = {
  invite: ''
};
