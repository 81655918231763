import i18n from 'i18next';
import getAxiosConnection from '../utils/axios';
import { PATH_AUTH } from '../routes/paths';

const initialState = {
  user: {},
  authenticating: false,
  authenticated: false,
  deleting: false,
  loadingUser: false,
  loadingUsers: false,
  loadingQuarterlyFuelConsumptions: false,
  loggedOut: false,
  quarterlyFuelConsumptions: [],
  registering: false,
  registered: false,
  resetting: false,
  reset: false,
  savingUser: false,
  users: [],
  verifying: false,
  impersonatedUser: ''
};

const userSlice = (set, get) => ({
  ...initialState,
  createUser: async (user) => {
    set({ savingUser: true });
    return getAxiosConnection()
      .post('users', { ...user })
      .then((res) => {
        let { users } = get();
        const addedUser = res.data;
        users = users.filter((user) => user.id !== addedUser.id);
        users.push(addedUser);
        if (user.id === addedUser.id) {
          addedUser.token = user.token;
          addedUser.refreshToken = user.refreshToken;
          set({ user: addedUser });
        }
        set({ users });
      })
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ savingUser: false });
      });
  },
  deleteUser: async (id) => {
    set({ deleting: true });
    return getAxiosConnection()
      .get(`user/delete/${id}`)
      .then((res) => {
        const oldUsers = get().users;
        const users = oldUsers.filter((user) => user.id !== id);
        if (res) {
          const removedUser = res.data;
          users.push(removedUser);
        }
        set({ users });
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        set({ deleting: false });
      });
  },
  getUser: async (id) => {
    set({ loadingUser: true });
    return getAxiosConnection()
      .get(`user/get/${id}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e);
        throw e;
      })
      .finally(() => {
        set({ loadingUser: false });
      });
  },
  login: async (username, password, rememberMe) => {
    set({ authenticating: true });
    const user = {
      username,
      password,
      remember: rememberMe
    };
    getAxiosConnection()
      .post('login', { ...user })
      .then((res) => {
        const authenticatedUser = {
          ...get().user,
          token: res.data.token,
          refreshToken: res.data.refresh_token
        };
        set({
          authenticating: false,
          authenticated: true,
          user: authenticatedUser
        });
        get().getUserInfo();
      })
      .catch(() => {
        console.log('error');
        set({ authenticating: false });
      });
  },
  getUsers: async (refresh = false) => {
    const { users } = get();
    if (!refresh && users.length > 0) {
      return;
    }
    set({ loadingUsers: true });
    getAxiosConnection()
      .get('users')
      .then((res) => {
        set({ users: res.data });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingUsers: false });
      });
  },
  getUserInfo: async () => {
    set({ loadingUser: true });
    return getAxiosConnection()
      .get(`user/current`)
      .then((res) => {
        const authenticatedUser = {
          ...get().user,
          ...res.data
        };
        set({
          user: authenticatedUser
        });
        i18n.changeLanguage(authenticatedUser.language);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingUser: false });
      });
  },
  getQuarterlyFuelConsumptions: async () => {
    set({ loadingQuarterlyFuelConsumptions: true });
    getAxiosConnection()
      .get(`/user/quarterlyFuelConsumptions`)
      .then((res) => {
        set({ quarterlyFuelConsumptions: res.data });
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        set({ loadingQuarterlyFuelConsumptions: false });
      });
  },
  passwordReset: async (email) => {
    set({ authenticating: true });
    const user = {
      email
    };
    getAxiosConnection()
      .post('forgotpassword', { ...user, url: process.env.REACT_APP_URL + PATH_AUTH.verify })
      .then(() => {
        set({
          resetting: false,
          reset: true
        });
      })
      .catch(() => {
        console.log('error');
        set({ resetting: false });
      });
  },
  setPassword: async (token, password) => {
    set({ verifying: true });
    return getAxiosConnection()
      .post('setpassword', { ...token, password })
      .then(() => 'success')
      .catch((e) => e)
      .finally(() => {
        set({
          verifying: false
        });
      });
  },
  logout: () => {
    set({ ...initialState, loggedOut: true }, true);
  },
  register: async (
    email,
    password,
    firstName,
    lastName,
    roles,
    publicData,
    invitationUUID = ''
  ) => {
    set({ registering: true });
    const { language } = i18n;
    const user = {
      email,
      password,
      firstName,
      lastName,
      roles,
      public: publicData,
      language,
      invitationUUID
    };
    return getAxiosConnection()
      .post('register', { ...user })
      .then(() => {
        set({
          registering: false,
          registered: true
        });
        get().refresh();
      })
      .catch((e) => {
        set({ registering: false });
        throw e;
      });
  },
  refresh: () => {
    set({ ...initialState }, true);
  },
  impersonateUser: async (userEmail) => {
    set({ impersonatedUser: userEmail });
    return get().getUserInfo();
  },
  promoteAdminUser: async (id) =>
    getAxiosConnection()
      .get(`/user/${id}/promote`)
      .then(() => get().getUsers(true))
      .catch((e) => {
        throw e;
      }),
  demoteAdminUser: async (id) =>
    getAxiosConnection()
      .get(`/user/${id}/demote`)
      .then(() => get().getUsers(true))
      .catch((e) => {
        throw e;
      })
});

export default userSlice;
