import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Register from './pages/Register';
import User from './pages/admin/User';
import NotFound from './pages/Page404';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import useStore from './store/useStore';
import Ship from './pages/Ship';
import Label from './pages/Label';
import QuarterlyReport from './pages/QuarterlyReport';
import Request from './pages/Request';
import UserCreate from './pages/admin/UserCreate';
import AdminRequest from './pages/admin/AdminRequest';
import RequestOverview from './components/_dashboard/admin/request/overview/RequestOverview';
import ShipOverview from './components/_dashboard/ship/ShipOverview';
import LabelRequestProcess from './components/_dashboard/process/LabelRequestProcess';
import Help from './pages/Help';
import ResetPassword from './pages/ResetPassword';
import DistributedLabel from './pages/authority/DistributedLabel';
import AdminFuels from './pages/admin/AdminFuels';
import AdminEngines from './pages/admin/AdminEngines';
import { PATH_ADMIN, PATH_AUTHORITY, PATH_CLIENT } from './routes/paths';
import Organization from './pages/Organization';
import AcceptOrganizationInvitation from './pages/AcceptOrganizationInvitation';
import RegisterWithInvite from './pages/RegisterWithInvite';

export default function Router() {
  const authenticated = useStore((state) => state.authenticated);
  const { user } = useStore((state) => state);
  const isAdmin = user?.roles?.includes('ROLE_ADMIN');
  const isAuthority = user?.roles?.includes('ROLE_AUTHORITY');
  let baseRoute;
  if (isAdmin) {
    baseRoute = 'admin';
  } else {
    baseRoute = isAuthority ? 'authority' : 'client';
  }

  return useRoutes([
    {
      path: PATH_CLIENT.root,
      // eslint-disable-next-line no-nested-ternary
      element: authenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: PATH_CLIENT.root, element: <Navigate to="/client/ship" replace /> },
        { path: PATH_CLIENT.accept_invite, element: <AcceptOrganizationInvitation /> },
        { path: PATH_CLIENT.ship, element: <Ship /> },
        { path: PATH_CLIENT.ship_details, element: <ShipOverview /> },
        { path: PATH_CLIENT.ship_create, element: <ShipOverview /> },
        { path: PATH_CLIENT.hours, element: <QuarterlyReport /> },
        { path: PATH_CLIENT.label, element: <Label /> },
        { path: PATH_CLIENT.request, element: <Request /> },
        { path: PATH_CLIENT.organization, element: <Organization /> },
        { path: PATH_CLIENT.user, element: <User /> },
        { path: PATH_CLIENT.wizard, element: <LabelRequestProcess /> },
        { path: PATH_CLIENT.help, element: <Help /> }
      ]
    },
    {
      path: PATH_ADMIN.root,
      element: isAdmin ? <DashboardLayout /> : <Navigate to="/client" />,
      children: [
        { path: PATH_ADMIN.root, element: <Navigate to="/admin/requests" replace /> },
        { path: PATH_ADMIN.users, element: <User /> },
        { path: PATH_ADMIN.user_create, element: <UserCreate /> },
        { path: PATH_ADMIN.user, element: <UserCreate /> },
        { path: PATH_ADMIN.requests, element: <AdminRequest /> },
        { path: PATH_ADMIN.request, element: <RequestOverview /> },
        { path: PATH_ADMIN.engines, element: <AdminEngines /> },
        { path: PATH_ADMIN.fuels, element: <AdminFuels /> }
      ]
    },
    {
      path: PATH_AUTHORITY.root,
      element: isAuthority ? <DashboardLayout /> : <Navigate to="/client" />,
      children: [
        { path: PATH_AUTHORITY.root, element: <Navigate to="/authority/labels" replace /> },
        { path: PATH_AUTHORITY.labels, element: <DistributedLabel /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: 'forgot', element: <ForgotPassword /> },
        { path: 'verify/:token', element: <ResetPassword /> },
        { path: 'register', element: <Register /> },
        { path: 'accept_invite', element: <RegisterWithInvite /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to={`/${baseRoute}`} /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
